fieldset{
    border-radius: 30px !important;
    /* border:none !important */
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}
::-webkit-scrollbar-thumb {
    background: #756F6F;
    border-radius: 10px;
}
::selection {
    color: white;
    background: black;
}

